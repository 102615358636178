'use client';

import type { Dispatch, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useReducer } from 'react';

const validViews = ['Admin', 'Dashboard', 'Analytics'] as const;
const validStatus = ['active', 'inactive', 'both'] as const;

type View = (typeof validViews)[number];
type Status = (typeof validStatus)[number];

// Define the shape of the state
interface State {
  view: View;
  status: Status;
}

// Define the initial state
const initialState: State = {
  view: 'Admin',
  status: 'active'
};

// Define action types
type Action =
  | { type: 'SET_VIEW'; payload: State['view'] }
  | { type: 'SET_STATUS'; payload: 'active' | 'inactive' | 'both' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_VIEW':
      return { ...state, view: action.payload };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }
};

const isValidView = (view: string): view is View => validViews.includes(view as View);
const isValidStatus = (status: string): status is Status => validStatus.includes(status as Status);

const loadState = (): State => {
  const statusStr = localStorage.getItem('status');
  const viewStr = localStorage.getItem('view');
  const licenseStr = localStorage.getItem('licenseID');

  return {
    ...initialState,
    view: viewStr && isValidView(viewStr) ? viewStr : initialState.view,
    status: statusStr && isValidStatus(statusStr) ? statusStr : initialState.status
  };
};

const saveState = (state: State) => {
  const { view, status } = state;
  localStorage.setItem('view', view || '');
  localStorage.setItem('status', status || '');
};

// Define context type
interface StateContextProps {
  state: State;
  dispatch: Dispatch<Action>;
}

// Create context
const GlobalStateContext = createContext<StateContextProps | undefined>(undefined);

// Context provider component
const GlobalStateProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    if (typeof window !== 'undefined') {
      return loadState();
    }
    return initialState;
  });

  useEffect(() => {
    saveState(state);
  }, [state]);

  return <GlobalStateContext.Provider value={{ state, dispatch }}>{children}</GlobalStateContext.Provider>;
};

const useStateContext = (): StateContextProps => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useStateContext must be used within a GlobalStateProvider');
  }
  return context;
};

export { GlobalStateProvider, useStateContext };
