export * from './auth';
export * from './client';
export * from './fusion';
export * from './license';
export * from './oauth';
export * from './workfront';

export const isValidJson = (jsonString: string): boolean => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};
