import { isValidJson } from '@/src/api/service/index';
import type { ApiResponse } from '@/src/core/rest-api/types';
import type { LicenseExtended } from '@/src/domain/entities';

export const license = {
  getAll: async (clientId: string, cookie: string | null): Promise<LicenseExtended[]> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/licenses?clientId=${clientId}`, {
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`,
        Cookie: `${cookie}`
      }
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<LicenseExtended[]> = JSON.parse(responseText);
        return (data.success && data.data) || [];
      }
    }

    return [];
  },
  create: async (license: Partial<LicenseExtended>): Promise<LicenseExtended | undefined> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/licenses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      },
      body: JSON.stringify(license)
    });

    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<LicenseExtended> = JSON.parse(responseText);
        return (data.success && data.data) || undefined;
      }
    }

    return undefined;
  },
  update: async (license: Partial<LicenseExtended>): Promise<LicenseExtended | undefined> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/licenses/${license.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      },
      body: JSON.stringify(license)
    });

    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<LicenseExtended> = JSON.parse(responseText);
        return (data.success && data.data) || undefined;
      }
    }

    return undefined;
  },
  delete: async (id: number): Promise<boolean> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/licenses/${id}`, {
      method: 'DELETE',
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      }
    });

    return response.ok;
  }
};
