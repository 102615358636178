import { isValidJson } from '@/src/api/service/index';
import type { ApiResponse } from '@/src/core/rest-api/types';
import type { OauthAppExtended } from '@/src/domain/entities';

export const oauth = {
  getAll: async (clientId: string, cookie: string | null): Promise<OauthAppExtended[]> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/oauth-apps?clientId=${clientId}`, {
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`,
        Cookie: `${cookie}`
      }
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<OauthAppExtended[]> = JSON.parse(responseText);

        return (data.success && data.data) || [];
      }
    }

    return [];
  },
  create: async (data: Partial<OauthAppExtended>): Promise<OauthAppExtended | undefined> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/oauth-apps`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<OauthAppExtended> = JSON.parse(responseText);

        return (data.success && data.data) || undefined;
      }
    }

    return undefined;
  },
  update: async (data: Partial<OauthAppExtended>): Promise<OauthAppExtended | undefined> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/oauth-apps/${data.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<OauthAppExtended> = JSON.parse(responseText);

        return (data.success && data.data) || undefined;
      }
    }

    return undefined;
  },
  delete: async (id: number): Promise<boolean> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/oauth-apps/${id}`, {
      method: 'DELETE',
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      }
    });
    return response.ok;
  }
};
